@forward './variables.scss';
@forward './typography.scss';
@forward './mixins.scss';
@forward './common.scss';
@forward './global/toggle-tip.module.scss';


.skeletonText {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #f1f1f1;
  border-radius: 10px;
  border: 1px solid #f1f1f1 !important;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f1f1f1;
    background-repeat: no-repeat;
    background-size: auto;
    content: '';
    z-index: 1;
  }
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
    animation: shimmer 2s infinite;
    content: '';
    z-index: 2;
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.skeletonBox {
  @extend .skeletonText;
  border-radius: getSize('s0');
}

// fix z-index of react-modal globally
.ReactModal__Overlay {
  z-index: 1;
}

// fix the modal scroll
.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100vh;
}

// STORYBOOK
.story {
  display: flex;
  flex-direction: column;
  background: #eee;
  padding: 16px;
  margin: 26px 0px;
  max-width: 600px;
  font-family: Arial, Helvetica, sans-serif;

  &__title {
    font-family: Arial, Helvetica, sans-serif;
    color: #666;
    font-size: 16px;
    margin-bottom: 16px;
  }

  &__description {
    font-family: Arial, Helvetica, sans-serif;
    color: #222;
    font-size: 14px;
    margin-bottom: 8px;
  }
}

.flex-microform {
  height: 48px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #757575;
  margin: 4px 0px;
  padding-left: 12px;
  padding-right: 12px;
}
.flex-microform-invalid {
  border-color: #cb333b;
  background-color: #ffebea;
}

.flex-microform-focused {
  box-shadow: 0px 0px 4px 0px #054eba;
  border: 1px solid #054eba;
  border-radius: 4px;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}
