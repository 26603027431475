@forward './colors.scss';

/**
 * @sass-export-section="spacing" 
 * @tokens Spacing
 * @presenter Spacing
 */
$spaceMap: (
  s0: 0px,
  s10: 2px,
  s20: 4px,
  s30: 8px,
  s40: 12px,
  s50: 16px,
  s60: 24px,
  s70: 32px,
  s80: 40px,
  s90: 56px,
  s100: 64px,
  s110: 152px,
);

/**
 * @sass-export-section="breakpoints" 
 * @tokens Breakpoints
 * @presenter Breakpoints
 */
$mobile-max: 650px;
$tablet-min: 651px;
$tablet-max: 950px;
$tablet-large-min: 1280px;
$desktop-max: 1376px;

//new/FS breakpoints:
$newTablet-max: 1023px;
$mobile-medium: 414px;

// please see hooks > get-screen-width > get-screen-width.js and the useWindowSize hook
// if you make a change here, you will also need to make it there as there is an
// issue with jest where it fails to import the variable when unit testing but it works during the build!
:export {
  size_mobile_max: $mobile-max;
  size_mobile_min: $tablet-min;
  size_tablet_max: $tablet-max;
  size_newTablet_max: $newTablet-max;
  size_desktop_max: $desktop-max;
}

//plp columns breakpoints
$desktop-3col-max: 1023px;
$tablet-2col-max: 912px;
//@end-sass-export-section [optional]

//assets
$awsBucket: 'https://assets.beautyboutique.ca/bb-prod-marketing-image/site-fonts';
$awsBucketImages: 'https://assets.beautyboutique.ca/bb-prod-marketing-image/site-media';
$awsBucketIcons: 'https://assets.beautyboutique.ca/bb-prod-marketing-image/site-icons/2020-site-icons';

//mobile facets
$facet-header-height: 40px;
$facet-footer-height: 76px;
