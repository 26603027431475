@use "Styles" as *;

.datepickerContainer {
  display: flex;
  flex-direction: column;
  width: 325px;
  box-shadow: 0 5px 20px -5px #333;
  bottom: 20px;
  margin-left: 30px;
  position: fixed;
  z-index: 1000;
  background-color: #ffffff;
  border-radius: 5px;
}

.datepickerLabel {
  font-size: 1.15rem;
  padding: 5px 15px;
  background-color: #e2dfdf;
}

.datepickerSelectedDate {
  font-size: 1.15rem;
  padding: 5px 0;
  background-color: #f0f0f0;
}

.datepicker {
  display: flex;
  padding: 10px;

  button {
    margin-left: 20px;
    padding: 0 3px;
    border: 1px solid black;
    background-color: #f0f0f0;
    border-radius: 5px;
  }
}
