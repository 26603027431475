@use './variables.scss' as *;
@use './typography.scss' as *;

@mixin fontHeadingLight($color: $primary-black, $font-weight: normal) {
  font-family: $font-heading-light;
  color: $color;
  font-weight: $font-weight;
  letter-spacing: 0.5px;
}

@mixin fontPrimary($color: $primary-black, $font-weight: normal, $letter-spacing: 1px) {
  font-family: $font-primary;
  color: $color;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
}

@mixin fontPrimaryMedium($color: $primary-black, $font-weight: normal, $letter-spacing: 0.25px) {
  font-family: $font-primary-medium;
  color: $color;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
}

@mixin fontPrimaryBold($color: $primary-black, $font-weight: normal) {
  font-family: $font-primary-bold;
  color: $color;
  font-weight: $font-weight;
}

@mixin fontPrimaryDisplayBold($color: $primary-black, $letter-spacing: 0.08px, $font-weight: normal) {
  font-family: $font-primary-display-bold;
  color: $color;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
}

@mixin fontPrimaryDisplay($color: $primary-black, $letter-spacing: 0.08px, $font-weight: normal) {
  font-family: $font-primary-display;
  color: $color;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
}

@mixin fontPrimaryDisplayLight($color: $primary-black, $letter-spacing: 0.08px, $font-weight: normal) {
  font-family: $font-primary-display-light;
  color: $color;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
}

@mixin fontPrimaryLight($color: $primary-black, $font-weight: normal) {
  font-family: $font-primary-light;
  color: $color;
  font-weight: $font-weight;
}

@mixin fontHeadingAustin($color: $primary-black, $font-weight: normal) {
  font-family: $font-heading-semibold;
  color: $color;
  font-weight: $font-weight;
}

@mixin fontHeadingAustinRoman($color: $primary-black, $font-weight: normal) {
  font-family: $font-heading-light;
  color: $color;
  font-weight: $font-weight;
}
@mixin notificationIcon($color: $sdm-red) {
  content: '!';
  border-radius: 50%;
  padding: 3px 10px 2px 9px;
  color: $color;
  border: 2px solid $color;
  background-color: $sdm-white-1;
}

//SDM PATTERN LIBRARY
@mixin fixedP1($color: $charcoal, $font-weight: normal) {
  font-family: $font-primary-bold;
  line-height: 20px;
  font-size: 1rem;
  color: $color;
}

// Potential SDM PATTERN LIBRARY
@mixin offerText($color: $sdm-red, $font-weight: normal) {
  font-family: $font-primary-display-bold;
  color: $color;
  font-weight: $font-weight;
}

@mixin T1_Desktop($color: $charcoal, $letter-spacing: 0.08px, $font-weight: normal) {
  @include fontPrimaryDisplayBold($color, $letter-spacing, $font-weight);
  font-size: $rem44;
  line-height: 48px;
}

@mixin T1_Tablet($color: $charcoal, $letter-spacing: 0.08px, $font-weight: normal) {
  @include fontPrimaryDisplayBold($color, $letter-spacing, $font-weight);
  font-size: $rem32;
  line-height: 36px;
}
@mixin T1_Mobile($color: $charcoal, $letter-spacing: 0.08px, $font-weight: normal) {
  @include fontPrimaryDisplayBold($color, $letter-spacing, $font-weight);
  font-size: $rem28;
  line-height: 32px;
}

@mixin T1 {
  @include T1_Desktop;
  @media screen and (max-width: $newTablet-max) {
    @include T1_Tablet;
  }
  @media screen and (max-width: $mobile-max) {
    @include T1_Mobile;
  }
}

@mixin T2_Desktop($color: $charcoal, $letter-spacing: 0.08px, $font-weight: normal) {
  @include fontPrimaryDisplayBold($color, $letter-spacing, $font-weight);
  font-size: $rem30;
  line-height: 32px;
}

@mixin T2_Tablet($color: $charcoal, $letter-spacing: 0.08px, $font-weight: normal) {
  @include fontPrimaryDisplayBold($color, $letter-spacing, $font-weight);
  font-size: $rem28;
  line-height: 32px;
}

@mixin T2_Mobile($color: $charcoal, $letter-spacing: 0.08px, $font-weight: normal) {
  @include fontPrimaryDisplayBold($color, $letter-spacing, $font-weight);
  font-size: $rem22;
  line-height: 24px;
}

@mixin T2($color: $charcoal, $letter-spacing: 0.08px, $font-weight: normal) {
  @include T2_Desktop($color, $letter-spacing, $font-weight);
  @media screen and (max-width: $newTablet-max) {
    @include T2_Tablet($color, $letter-spacing, $font-weight);
  }
  @media screen and (max-width: $mobile-max) {
    @include T2_Mobile($color, $letter-spacing, $font-weight);
  }
}

@mixin B1_Desktop($color: $charcoal, $letter-spacing: 0.08px, $font-weight: normal) {
  @include fontPrimary($color, $font-weight, $letter-spacing);
  font-size: $rem18;
  line-height: 24px;
}

@mixin B1_Tablet_Mobile($color: $charcoal, $letter-spacing: 0.08px, $font-weight: normal) {
  @include fontPrimary($color, $font-weight, $letter-spacing);
  font-size: $rem16;
  line-height: 24px;
}

@mixin B1_Body($color: $charcoal, $letter-spacing: 0.08px, $font-weight: normal) {
  @include fontPrimary($color, $font-weight, $letter-spacing);
  font-size: $rem16;
  line-height: 20px;
}
@mixin L1_Text_Link($color: $charcoal, $letter-spacing: 0px, $font-weight: normal) {
  @include B1_Body($color, $font-weight, $letter-spacing);
  text-decoration: underline;
}

@mixin scrollbars($width: 8px, $foreground-color: $sdm-gray-7, $background-color: $sdm-gray-9) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $foreground-color;
    border: 0px;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border: 0px;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin L3_CTA_Primary($letterColor: $white, $letter-spacing: 0.25px, $font-weight: bold, $font-size: $rem18) {
  @include fontPrimary($letterColor, $font-weight, $letter-spacing);
  font-size: $font-size;
}

/** SDM Foundational Library **/
@mixin focus(
  $horizontal: s0,
  $vertical: s0,
  $blur: s20,
  $spread: s20,
  $color: ui-blue-focus,
  $opacity: 0.5,
  $inset: null
) {
  @include box-shadow($horizontal, $vertical, $blur, $spread, $color, $opacity, $inset);
}

/********SPACE******/
@function getSize($size) {
  @return map-get($spaceMap, $size);
}

/**padding**/
@mixin padding($size) {
  padding: getSize($size);
}
@mixin padding-top($size) {
  padding-top: getSize($size);
}
@mixin padding-bottom($size) {
  padding-bottom: getSize($size);
}
@mixin padding-left($size) {
  padding-left: getSize($size);
}
@mixin padding-right($size) {
  padding-right: getSize($size);
}
@mixin padding-horizontal($size) {
  padding-left: getSize($size);
  padding-right: getSize($size);
}
@mixin padding-vertical($size) {
  padding-top: getSize($size);
  padding-bottom: getSize($size);
}

/**margin**/
@mixin margin($size) {
  margin: getSize($size);
}
@mixin margin-top($size) {
  margin-top: getSize($size);
}
@mixin margin-bottom($size) {
  margin-bottom: getSize($size);
}
@mixin margin-left($size) {
  margin-left: getSize($size);
}
@mixin margin-right($size) {
  margin-right: getSize($size);
}
@mixin margin-horizontal($size) {
  margin-left: getSize($size);
  margin-right: getSize($size);
}
@mixin margin-vertical($size) {
  margin-top: getSize($size);
  margin-bottom: getSize($size);
}

@function getColor($color) {
  @return map-get($colorMap, $color);
}

@mixin color($color) {
  color: getColor($color);
}

@mixin background-color($color) {
  background-color: getColor($color);
}

@mixin font-sizes($size) {
  font-size: map-get($fontSizes, $size);
}

@mixin letterSpacing($size) {
  letter-spacing: map-get($letterSpacingMap, $size);
}

@mixin lineHeight($size) {
  line-height: map-get($lineHeightMap, $size);
}

@mixin text_styles(
  $fontFamily,
  $fontSize,
  $lineHeight,
  $letterSpacing,
  $color,
  $italic,
  $fontFamilyItalic,
  $underline,
  $strikethrough,
  $bold: false,
  $fontFamilyBold: null,
  $fontFamilyBoldAndItalic: null
) {
  font-family: $fontFamily;
  @include font-sizes($fontSize);
  @include lineHeight($lineHeight);

  @if $letterSpacing {
    @include letterSpacing($letterSpacing);
  }
  @include color($color);

  @if $italic {
    font-family: $fontFamilyItalic;
  }
  @if $bold {
    font-family: $fontFamilyBold;
  }
  @if $bold and $italic {
    font-family: $fontFamilyBoldAndItalic;
  }
  @if $underline {
    text-decoration: underline;
  }
  @if $strikethrough {
    text-decoration: line-through;
  }
}

@mixin Title_XXL($color: text-black-100, $italic: false, $underline: false, $strikethrough: false) {
  @include text_styles(
    $font-primary-display-bold,
    rem44,
    large,
    ultra-compressed,
    $color,
    $italic,
    $font-primary-display-bold-italic,
    $underline,
    $strikethrough
  );

  @media screen and (max-width: $newTablet-max) {
    @include font-sizes(rem32);
  }

  @media screen and (max-width: $mobile-max) {
    @include font-sizes(rem28);
  }
}

@mixin Title_XL($color: text-black-100, $italic: false, $underline: false, $strikethrough: false) {
  @include text_styles(
    $font-primary-display-bold,
    rem32,
    large,
    ultra-compressed,
    $color,
    $italic,
    $font-primary-display-bold-italic,
    $underline,
    $strikethrough
  );

  @media screen and (max-width: $newTablet-max) {
    @include font-sizes(rem24);
  }

  @media screen and (max-width: $mobile-max) {
    @include font-sizes(rem22);
  }
}

@mixin Title_L($color: text-black-100, $italic: false, $underline: false, $strikethrough: false) {
  @include text_styles(
    $font-primary-display-bold,
    rem24,
    large,
    ultra-compressed,
    $color,
    $italic,
    $font-primary-display-bold-italic,
    $underline,
    $strikethrough
  );

  @media screen and (max-width: $newTablet-max) {
    @include font-sizes(rem20);
  }
}

@mixin Title_M($color: text-black-100, $italic: false, $underline: false, $strikethrough: false) {
  @include text_styles(
    $font-primary-display-bold,
    rem18,
    medium,
    compressed,
    $color,
    $italic,
    $font-primary-display-bold-italic,
    $underline,
    $strikethrough
  );
}

@mixin Title_S($color: text-black-100, $italic: false, $underline: false, $strikethrough: false) {
  @include text_styles(
    $font-primary-bold,
    rem16,
    small,
    compressed,
    $color,
    $italic,
    $font-primary-bold-italic,
    $underline,
    $strikethrough
  );
}

@mixin Title_XS($color: text-black-100, $italic: false, $underline: false, $strikethrough: false) {
  @include text_styles(
    $font-primary-bold,
    rem12,
    small,
    compressed,
    $color,
    $italic,
    $font-primary-bold-italic,
    $underline,
    $strikethrough
  );
}
@mixin Text_UI_XXL($color: text-black-100, $italic: false, $underline: false, $strikethrough: false, $bold: false) {
  @include text_styles(
    $font-primary,
    rem44,
    large,
    false,
    $color,
    $italic,
    $font-primary-italic,
    $underline,
    $strikethrough,
    $bold,
    $font-primary-bold
  );

  @media screen and (max-width: $newTablet-max) {
    @include font-sizes(rem32);
  }

  @media screen and (max-width: $mobile-max) {
    @include font-sizes(rem28);
  }
}

@mixin Text_UI_XL($color: text-black-100, $italic: false, $underline: false, $strikethrough: false, $bold: false) {
  @include text_styles(
    $font-primary,
    rem32,
    large,
    false,
    $color,
    $italic,
    $font-primary-italic,
    $underline,
    $strikethrough,
    $bold,
    $font-primary-bold
  );

  @media screen and (max-width: $newTablet-max) {
    @include font-sizes(rem24);
  }

  @media screen and (max-width: $mobile-max) {
    @include font-sizes(rem24);
  }
}

@mixin Text_UI_L($color: text-black-100, $italic: false, $underline: false, $strikethrough: false, $bold: false) {
  @include text_styles(
    $font-primary,
    rem18,
    standard,
    medium,
    $color,
    $italic,
    $font-primary-italic,
    $underline,
    $strikethrough,
    $bold,
    $font-primary-bold,
    $font-primary-bold-italic
  );

  @media screen and (max-width: $newTablet-max) {
    @include font-sizes(rem18);
  }

  @media screen and (max-width: $mobile-max) {
    @include font-sizes(rem18);
  }
}

@mixin Text_UI_M($color: text-black-100, $italic: false, $underline: false, $strikethrough: false, $bold: false) {
  @include text_styles(
    $font-primary,
    rem16,
    standard,
    small,
    $color,
    $italic,
    $font-primary-italic,
    $underline,
    $strikethrough,
    $bold,
    $font-primary-bold
  );
}

@mixin Text_UI_S($color: text-black-100, $italic: false, $underline: false, $strikethrough: false, $bold: false) {
  @include text_styles(
    $font-primary,
    rem14,
    standard,
    small,
    $color,
    $italic,
    $font-primary-italic,
    $underline,
    $strikethrough,
    $bold,
    $font-primary-bold
  );
}

@mixin Text_UI_XS($color: text-black-100, $italic: false, $underline: false, $strikethrough: false, $bold: false) {
  @include text_styles(
    $font-primary,
    rem12,
    standard,
    small,
    $color,
    $italic,
    $font-primary-italic,
    $underline,
    $strikethrough,
    $bold,
    $font-primary-bold
  );
}

@mixin border($color: text-grey-100, $fillStyle: solid, $width: 1px) {
  border: $width $fillStyle getColor($color);
}

@mixin border-top($color: text-grey-100, $fillStyle: solid, $width: 1px) {
  border-top: $width $fillStyle getColor($color);
}
@mixin border-bottom($color: text-grey-100, $fillStyle: solid, $width: 1px) {
  border-bottom: $width $fillStyle getColor($color);
}
@mixin border-left($color: text-grey-100, $fillStyle: solid, $width: 1px) {
  border-left: $width $fillStyle getColor($color);
}
@mixin border-right($color: text-grey-100, $fillStyle: solid, $width: 1px) {
  border-right: $width $fillStyle getColor($color);
}
@mixin border-horizontal($color: text-grey-100, $fillStyle: solid, $width: 1px) {
  border-right: $width $fillStyle getColor($color);
  border-left: $width $fillStyle getColor($color);
}
@mixin border-vertical($color: text-grey-100, $fillStyle: solid, $width: 1px) {
  border-top: $width $fillStyle getColor($color);
  border-bottom: $width $fillStyle getColor($color);
}

@mixin box-shadow(
  $horizontal: s0,
  $vertical: s0,
  $blur: s20,
  $spread: s20,
  $color: ui-blue-focus,
  $opacity: 1,
  $inset: null,
  $outline-width: 0
) {
  box-shadow: getSize($horizontal) getSize($vertical) getSize($blur) getSize($spread) rgba(getColor($color), $opacity),
    $inset;
  -webkit-box-shadow: getSize($horizontal) getSize($vertical) getSize($blur) getSize($spread)
      rgba(getColor($color), $opacity),
    $inset;
  -moz-box-shadow: getSize($horizontal) getSize($vertical) getSize($blur) getSize($spread)
      rgba(getColor($color), $opacity),
    $inset;
  outline-width: $outline-width;
}

@mixin box-shadow-inset($horizontal: s0, $vertical: s0, $blur: s0, $spread: 3px, $color: ui-white-100, $opacity: 1) {
  box-shadow: inset getSize($horizontal) getSize($vertical) getSize($blur) $spread rgba(getColor($color), $opacity);
  -webkit-box-shadow: inset getSize($horizontal) getSize($vertical) getSize($blur) $spread
    rgba(getColor($color), $opacity);
  -moz-box-shadow: inset getSize($horizontal) getSize($vertical) getSize($blur) $spread rgba(getColor($color), $opacity);
}

/**box shadow elevation tokens**/

@mixin elevation-01-low() {
  box-shadow: 0 1px 5px 0 rgba(getColor(ui-grey-100), 0.5);
}

@mixin elevation-02-medium() {
  box-shadow: 0 5px 10px 0 rgba(getColor(ui-grey-100), 0.25);
}

@mixin elevation-03-high() {
  box-shadow: 0 12px 22px 0 rgba(getColor(ui-grey-100), 0.25);
}
