@use './mixins' as *;

:root {
  font-size: 12px;
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono', 'Oxygen Mono',
    'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(#00000080, #00000040, #00000030, #00000020, #00000010, #00000010, #00000080);

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;

  //set font styles on firework widgets
  --fw-font-family: 'GT Eesti Display', serif;
  --fw-thumbnail-font-weight: 400;
  --fw-thumbnail-font-size: 18px;
}

/* Dark theme disabled for now */
/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right, rgba(1, 65, 255, 0), rgba(1, 65, 255, 0), rgba(1, 65, 255, 0.3));

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(#ffffff80, #ffffff40, #ffffff30, #ffffff20, #ffffff10, #ffffff10, #ffffff80);

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }

  html {
    color-scheme: dark;
  }
} */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
}

body {
  color: rgb(var(--foreground-rgb));
  @include background-color(ui-white-100);
  //this is to hide the default button to launch ada chatbot. Customized launch chatbot button logic is handled in LDP core via horizontal feature card.
  #ada-button-frame {
    display: none;
  }
}

// For context: MB-15703
.link-list-item:first-child > a {
  font-weight: 300 !important;
}

body#body {
  opacity: 1 !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow: hidden;
  overflow-y: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li {
  margin: 0;
  padding: 0;
  line-height: inherit;
  letter-spacing: inherit;
}

a {
  text-decoration: none;
  text-align: left;
}

img {
  display: block;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: inherit;
}

button {
  background: none;
  border: none;
}

#sdm-mobile-app-banner {
  position: absolute;
  width: 100%;
  z-index: 100000;
}

#lds__privacy-policy,
.lds__privacy-policy__innerWrapper > button {
  @include Text_UI_M($color: text-white-100);
  font-size: 16px;
}
body:has(.chakra-modal__content-container) {
  #nebula_div_btn,
  #sf-widget-companion-wrapper {
    @media screen and (max-width: 650px) {
      display: none !important;
    }
  }
}
