@use './variables.scss' as *;

// load the fonts

@font-face {
  font-family: 'Austin-Roman';
  src: url('https://assets.beautyboutique.ca/bb-prod-marketing-image/site-fonts/austin/web/Austin-Roman-Web.woff')
    format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Austin-SemiboldItalic';
  src: url('https://assets.beautyboutique.ca/bb-prod-marketing-image/site-fonts/austin/web/Austin-SemiboldItalic-Web.eot')
      format('embedded-opentype'),
    url('https://assets.beautyboutique.ca/bb-prod-marketing-image/site-fonts/austin/web/Austin-SemiboldItalic-Web.woff')
      format('woff'),
    url('https://assets.beautyboutique.ca/bb-prod-marketing-image/site-fonts/austin/web/Austin-SemiboldItalic-Web.woff2')
      format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT-Eesti-Text-Light';
  src: url('https://bb-prod-marketing-image.s3.amazonaws.com/site-fonts/gt-eesti/GT-Eesti-Text-Light.otf')
    format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'GT-Eesti-Text-Regular';
  src: url('https://bb-prod-marketing-image.s3.amazonaws.com/site-fonts/site-fonts-2020/GT-Eesti-Text-Regular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'GT-Eesti-Text-Regular-Italic';
  src: url($awsBucket + '/site-fonts-2020/GT-Eesti-Text-Regular-Italic.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'GT-Eesti-Text-Medium';
  src: url('https://bb-prod-marketing-image.s3.amazonaws.com/site-fonts/site-fonts-2020/GT-Eesti-Text-Medium.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'GT-Eesti-Text-Book';
  src: url($awsBucket + '/site-fonts-2020/GT-Eesti-Text-Book.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'GT-Eesti-Text-Bold';
  src: url('https://bb-prod-marketing-image.s3.amazonaws.com/site-fonts/site-fonts-2020/GT-Eesti-Text-Bold.otf')
    format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'GT-Eesti-Text-Bold-Italic';
  src: url('https://bb-prod-marketing-image.s3.amazonaws.com/site-fonts/site-fonts-2020/GT-Eesti-Text-Bold-Italic.otf')
    format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'GT-Eesti-Display';
  src: url('https://bb-prod-marketing-image.s3.amazonaws.com/site-fonts/site-fonts-2020/GT-Eesti-Display-Regular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'GT-Eesti-Display-Bold';
  src: url('https://bb-prod-marketing-image.s3.amazonaws.com/site-fonts/site-fonts-2020/GT-Eesti-Display-Bold.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'GT-Eesti-Display-Bold-Italic';
  src: url($awsBucket + '/site-fonts-2020/GT-Eesti-Display-Bold-Italic.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'GT-Eesti-Display-Light';
  src: url($awsBucket + '/site-fonts-2020/GT-Eesti-Display-Light.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src: url('https://bb-prod-marketing-image.s3.amazonaws.com/site-fonts/icomoon/icomoon.eot');
  src: url('https://bb-prod-marketing-image.s3.amazonaws.com/site-fonts/icomoon/icomoon.eot?vgwteq#iefix') format('embedded-opentype'),
    url('https://bb-prod-marketing-image.s3.amazonaws.com/site-fonts/icomoon/icomoon.ttf?vgwteq') format('truetype'),
    url('https://bb-prod-marketing-image.s3.amazonaws.com/site-fonts/icomoon/icomoon.woff?vgwteq') format('woff'),
    url('https://bb-prod-marketing-image.s3.amazonaws.com/site-fonts/icomoon/icomoon.svg?vgwteq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

$rem10: 0.625rem;
$rem11: 0.6875rem;
$rem12: 0.75rem;
$rem13: 0.8125rem;
$rem14: 0.875rem;
$rem15: 0.9375rem;
$rem16: 1rem;
$rem18: 1.125rem;
$rem20: 1.25rem;
$rem21: 1.3125rem;
$rem22: 1.375rem;
$rem24: 1.5rem;
$rem27: 1.6875rem;
$rem28: 1.75rem;
$rem30: 1.875rem;
$rem32: 2rem;
$rem36: 2.25rem;
$rem38: 2.375rem;
$rem40: 2.5rem;
$rem42: 2.625rem;
$rem44: 2.75rem;
$rem51: 3.1875rem;
$rem60: 3.75rem;

/**
 * @sass-export-section="font-sizes"
 * @tokens Font Size
 * @presenter FontSize
 */
$fontSizes: (
  rem10: 0.625rem,
  rem11: 0.6875rem,
  rem12: 0.75rem,
  rem13: 0.8125rem,
  rem14: 0.875rem,
  rem15: 0.9375rem,
  rem16: 1rem,
  rem18: 1.125rem,
  rem20: 1.25rem,
  rem21: 1.3125rem,
  rem22: 1.375rem,
  rem24: 1.5rem,
  rem27: 1.6875rem,
  rem28: 1.75rem,
  rem30: 1.875rem,
  rem32: 2rem,
  rem36: 2.25rem,
  rem38: 2.375rem,
  rem40: 2.5rem,
  rem42: 2.625rem,
  rem44: 2.75rem,
  rem51: 3.1875rem,
  rem60: 3.75rem,
);
//@end-sass-export-section [optional]

/**
 * @sass-export-section="line-height"
 * @tokens LineHeight
 * @presenter LineHeight
 */
$lineHeightMap: (
  standard: 1.5em,
  compressed: 1.3em,
  ultra-compressed: 1.2em,
);

/**
 * @sass-export-section="letter-spacing"
 * @tokens LetterSpacing
 * @presenter LetterSpacing
 */
$letterSpacingMap: (
  x-large: 0.48px,
  large: 0.32px,
  medium: 0.24px,
  small: 0.16px,
);

/**
 * @sass-export-section="font-family"
 * @tokens Font Family
 * @presenter FontFamily
 */
$font-primary: 'GT-Eesti-Text-Regular', Sans-Serif;
$font-primary-italic: 'GT-Eesti-Text-Regular-Italic', Sans-Serif;
$font-primary-bold: 'GT-Eesti-Text-Bold', Sans-Serif;
$font-primary-bold-italic: 'GT-Eesti-Text-Bold-Italic', Sans-Serif;
$font-primary-medium: 'GT-Eesti-Text-Medium', Sans-Serif;
$font-primary-light: 'GT-Eesti-Text-Light', Sans-Serif;
$font-primary-book: 'GT-Eesti-Text-Book', Sans-Serif;
$font-primary-display-bold: 'GT-Eesti-Display-Bold', Sans-Serif;
$font-primary-display-bold-italic: 'GT-Eesti-Display-Bold-Italic', Sans-Serif;

$font-primary-display: 'GT-Eesti-Display', Sans-Serif;
$font-primary-display-light: 'GT-Eesti-Display-Light', Sans-Serif;
$font-heading-semibold: 'Austin-SemiboldItalic', Georgia;
$font-heading-light: 'Austin-Roman', Georgia;
//@end-sass-export-section [optional]
