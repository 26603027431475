/**
* @sass-export-section="foundational-colours"
* @tokens Colors
* @presenter Color
**/
$colorMap: (
  text-black-100: #2f2f2f,
  text-grey-100: #757575,
  text-white-100: #ffffff,
  text-red-100: #cb333b,
  ui-black-100: #2f2f2f,
  ui-grey-100: #757575,
  ui-grey-75: #c1c1c1,
  ui-grey-50: #dddddd,
  ui-grey-10: #f1f1f1,
  ui-blue-100: #1b3b4a,
  ui-blue-75: #007faa,
  ui-orange-100: #ffae58,
  ui-orange-10: #fff0e0,
  ui-green-100: #00853d,
  ui-green-10: #e7f3ed,
  ui-red-100: #cb333b,
  ui-red-10: #ffebea,
  ui-white-100: #ffffff,
  ui-blue-focus: #054eba,
);

@function str-replace($string, $search: '-', $replace: '_') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
:export {
  @each $key, $value in $colorMap {
    #{str-replace($key)}: $value;
  }
}

/**
* @sass-export-section="brand-colours"
* @tokens Colors
* @presenter Color
**/
$primary-black: #2f2f2f;
$secondary-black: rgb(47, 47, 47);
$sdm-black-1: #000;
$sdm-black-3: rgb(25, 25, 25);
$sdm-black-4: #191919;
$black: #000000;

$primary-gray: #e7e7e7;
$secondary-gray: #f6f6f6;
$tertiary-gray: #ccc;
$border-gray: #dddddd;
$active-link-gray: #888888;
$underline-gray: #979797;
$inactive-gray: #6b6272;

$sdm-white-1: #fff;
$sdm-white-2: #f7f5f5;

$sdm-pink-gray: #faf6f7;

$sdm-red: #cb333b !default;
$pco-red: #cb333b;
$pco-pink: #f9dada;
$sdm-gray: #e7e7e7;
$light-gray: #a9a9a9;

$primary-gray: #e7e7e7;
$secondary-gray: #f6f6f6;
$tertiary-gray: #ccc;

$sdm-gray-1: #8f8f8f;
$sdm-gray-2: #bababa;
$sdm-gray-3: #ddd;
$sdm-gray-4: #757575;
$sdm-gray-5: #979797;
$sdm-gray-6: #444444;
$sdm-gray-7: #8a8a8a;
$sdm-gray-8: #878787;
$sdm-gray-9: #f1f1f1;

$dark-blue: #1c3c4a;
$bright-blue: #007faa;

$sdm-outline-blue: #3b99fc;
$sdm-light-blue: #e8f3fa;

// error
$error-red-1: #ee414c;
$error-red-2: #ffebea;
$email-input-error: #bf222d;

// warning
$warning-yellow-1: #fff0e0;
$warning-yellow-2: #ffae58;

// SDM DESIGN PATTERN LIBRARY
// sdm pattern variables point to above variables to avoid duplicate declaration
$midnight-blue: #1b3b4a;
$white: $sdm-white-1;
$charcoal: $primary-black;
// GREYS - order them by contrast lightest to darkest
// placeholder for grey1 after discuss this spectrum with design
$grey-2: $sdm-gray-4;
// placeholder for grey2 after discuss this spectrum with design
$grey-3: $sdm-gray-7;
$grey-4: $sdm-gray-3;
$light-grey: $sdm-gray-9;

$masthead-red: #ba272f;

@function whiteOpacity($opacity: 1) {
  @return rgba(255, 255, 255, $opacity);
}
